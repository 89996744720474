import { apiCallMGMT, getAgeInYears, toBoolean } from '../util';
import {
  COSTCO_MEMBERSHIP_EDIT_CLICKED,
  COSTCO_MEMBERSHIP_ERROR_MESSAGE_SET,
  COSTCO_MEMBERSHIP_NUMBER_CHANGED,
  COSTCO_MEMBERSHIP_VALIDATED,
  COSTCO_MEMBERSHIP_VALIDATION_FAILED,
  COSTCO_MEMBERSHIP_VALIDATION_STARTED,
  LOADING_QUOTE_DATA_FINISH,
  NOTIFY_ME_EMAIL_VALUES_SAVED,
  PROCEED_WITHOUT_PARTNER_CHANGED,
} from './types';
import { rateSummary, updateRateQuote } from './quoting';
import { getStateAbbreviation } from '../utils/zipcode';
import { AFTER_RATE_TAG, PetType } from '../constants';
import { sendFSCLead } from './leads';

export function changeCostcoMembershipNumber(membershipNumber) {
  return {
    membershipNumber,
    type: COSTCO_MEMBERSHIP_NUMBER_CHANGED,
  };
}

const EXEC_MEMBERSHIP = 'EXEC';
const PAYMENT_SUMMARY = 'paymentSummary';
const MESSAGE_FIELD_NAME = 'MessageMembershipValidated';
const EXECUTIVE_MESSAGE_FIELD_NAME = 'MessageMembershipValidatedExecutive';

export function validateCostcoMembership({
  customerZipCode,
  membershipNumber,
  rateSummaryBody,
  waivableFee,
  useFeeWaived = false,
}) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: COSTCO_MEMBERSHIP_VALIDATION_STARTED,
      });

      const url = `api/costco/validatemember/${membershipNumber}`;

      const request = await apiCallMGMT(url);
      const costcoResponse = await request.json();

      if (costcoResponse.valid) {
        const {
          quoting: {
            partners: { data },
          },
        } = getState();
        const { CustomFields } = data;
        let stateIsNoFeeWaived = false;
        if (!useFeeWaived) {
          const stateAbbr = getStateAbbreviation(String(customerZipCode));
          // There are states where we are not allowed to give enrollment
          // fee waiver to executive members.
          const STATES_NO_FEE_WAIVED = ['MI', 'RI'];
          stateIsNoFeeWaived = STATES_NO_FEE_WAIVED.includes(stateAbbr);
        }

        const isExecutive = costcoResponse.membershipTier === EXEC_MEMBERSHIP;
        let messageName = MESSAGE_FIELD_NAME;
        const isFeeWaived =
          waivableFee > 0 && isExecutive && !stateIsNoFeeWaived;
        const fields = {};

        if (isFeeWaived) {
          const customFields = CustomFields
            .filter((field) => field.Section === PAYMENT_SUMMARY);
          customFields.forEach((field) => {
            fields[field.Name] = field.Content;
          });
          messageName = EXECUTIVE_MESSAGE_FIELD_NAME;
        }

        const messageField = CustomFields
          .find((field) => field.Name === messageName);
        const message = messageField ? messageField.Content : '';

        const membershipTypeName = isExecutive
          ? costcoResponse.membershipTierDescription
          : costcoResponse.membershipTypeDescription.replace(/\s/, '');

        dispatch({
          ...fields,
          customerZipCode,
          isFeeWaived,
          membershipTypeName,
          message,
          type: COSTCO_MEMBERSHIP_VALIDATED,
          waivableFee,
        });

        dispatch(rateSummary({
          ...rateSummaryBody,
          Partner: {
            ...rateSummaryBody.Partner,
            IsFeeWaived: isFeeWaived,
            MembershipType: membershipTypeName,
          },
        }));
      } else {
        dispatch({
          error: costcoResponse.errorMessage,
          type: COSTCO_MEMBERSHIP_VALIDATION_FAILED,
        });
      }
    } catch (_) {
      dispatch({
        error: 'Could not validate member ID, please try again',
        type: COSTCO_MEMBERSHIP_VALIDATION_FAILED,
      });
    }
  };
}

export function costcoMembershipEditClicked() {
  return {
    type: COSTCO_MEMBERSHIP_EDIT_CLICKED,
  };
}

export function costcoMembershipSetErrorMessage(errorMessage) {
  return {
    error: errorMessage,
    type: COSTCO_MEMBERSHIP_ERROR_MESSAGE_SET,
  };
}

export function changeProceedWithoutCostcoMembership({
  diamondClientId,
  effectiveDateCustom,
  eMail,
  employerName,
  nopCommerceClientId,
  parameters,
  petQuoteList,
  quoteId,
}) {
  return async (dispatch, getState) => {
    await dispatch({ type: PROCEED_WITHOUT_PARTNER_CHANGED });
    await dispatch(updateRateQuote({
      diamondClientId,
      effectiveDateCustom,
      eMail,
      employerName,
      nopCommerceClientId,
      parameters,
      petQuoteList,
      quoteId,
      showLoading: true,
      tag: AFTER_RATE_TAG.removePartner,
    }));
    // Remove promocode from FSC
    const { quoting: { data, newCustomer } } = getState();

    if (data) {
      data?.petQuoteResponseList.forEach((pet) => {
        dispatch(sendFSCLead({
          age: Number(getAgeInYears(pet.petAgeName)),
          breed: pet.breedName,
          breedId: pet.breedId,
          email: newCustomer.email || parameters.email,
          gender: pet.genderName,
          marketingOpt: toBoolean(parameters.marketingOptIn),
          petName: pet.petName,
          petType: PetType.Dog.value === pet.petType
            ? PetType.Dog.name : PetType.Cat.name,
          promoCode: '',
          state: newCustomer.address.state || '',
          zipCode: newCustomer.address.zipCode || parameters.petZipCode,
        }));
      });
    }
  };
}

export function saveNotifyMeValuesEmail({
  email,
  petAge,
  petBreed,
  petBreedId,
  petName,
  petSex,
  zipcode,
}) {
  return {
    type: NOTIFY_ME_EMAIL_VALUES_SAVED,
    valuesEmail: {
      email,
      petAge,
      petBreed,
      petBreedId,
      petName,
      petSex,
      zipcode,
    },
  };
}

export function hideLoading() {
  return { type: LOADING_QUOTE_DATA_FINISH };
}
