import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  costcoMembershipEditClicked,
  changeCostcoMembershipNumber,
  changeProceedWithoutCostcoMembership,
  validateCostcoMembership,
} from '../../../../actions/partners';
import { useRateSummary } from '../../../../hooks/useRateSummary';
import { LoadingButton } from '../../../common/LoadingButton';
import { BILLING_CYCLE_TYPE } from '../../../../constants';
import './CostcoMembershipValidator.css';
import { useFeeWaived } from '../../../../hooks';

const COSTCO_URL = 'https://www.costco.com';
const FIND_MEMBERSHIP_URL = 'https://customerservice.costco.com/app/'
  + 'answers/detail/a_id/7897/~/where-can-i-find-my-membership-number%3F';

export const CostcoMembershipValidator = ({ goToCustomize = () => { } }) => {
  const membershipInputRef = useRef();
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const dispatch = useDispatch();
  const { getSelectedRateBody } = useRateSummary();
  const isFeeWaived = useFeeWaived();

  const {
    customerZipCode,
    data: {
      insuranceProduct: {
        InsuranceProductFee,
      },
      petQuoteResponseList,
    },
    partners: {
      costcoMembership: {
        editing,
        error,
        isValid,
        loading,
        membershipNumber,
        membershipTypeName,
        message,
        waivableFee,
      },
      data: {
        MembershipValidation: {
          MembershipTypes,
        },
        PartnerGuid: partnerGuid,
      },
    },
    parameters,
    sessionInformation: { nopCommerceUser, userEmail },
  } = store;

  const membershipTypes = useMemo(() => {
    if (membershipTypeName) {
      return MembershipTypes
        .filter((membership) => membership.Name === membershipTypeName);
    }

    return [...MembershipTypes];
  }, [MembershipTypes, membershipTypeName]);

  const waivableFeeMemo = useMemo(() => {
    const { oneTime } = BILLING_CYCLE_TYPE;

    const insuranceProduct = InsuranceProductFee
      .find((Fee) => Fee.InsuranceFee.BillingCycleType === oneTime);
    if (insuranceProduct) {
      return insuranceProduct.InsuranceFee.Amount;
    }

    return 0;
  }, [InsuranceProductFee]);

  const callValidateCostcoMembership = useCallback(() => {
    if (loading) {
      return;
    }

    dispatch(validateCostcoMembership({
      customerZipCode,
      membershipNumber,
      partnerGuid,
      rateSummaryBody: getSelectedRateBody,
      useFeeWaived: isFeeWaived,
      waivableFee: waivableFeeMemo,
    }));
  }, [
    customerZipCode,
    dispatch,
    getSelectedRateBody,
    loading,
    membershipNumber,
    partnerGuid,
    waivableFeeMemo,
    isFeeWaived,
  ]);

  useEffect(() => {
    if (!loading && isValid && waivableFee !== waivableFeeMemo) {
      callValidateCostcoMembership();
    }
  }, [
    isValid,
    callValidateCostcoMembership,
    loading,
    waivableFee,
    waivableFeeMemo,
  ]);

  useEffect(() => {
    if (editing && membershipNumber) {
      const { current } = membershipInputRef;
      if (current) {
        current.focus();
        current.select();
      }
    }
  }, [editing]);

  function handleMembershipValidation(event) {
    event.preventDefault();

    if (editing) {
      callValidateCostcoMembership();
    } else {
      dispatch(costcoMembershipEditClicked());
    }
  }

  function handleMembershipNumberChanged(event) {
    const { value } = event.target;

    dispatch(changeCostcoMembershipNumber(value));
  }

  const handleProceedWithoutCostcoMembership = async () => {
    await dispatch(changeProceedWithoutCostcoMembership({
      diamondClientId: nopCommerceUser.DiamonClientdId,
      effectiveDateCustom: store.effectiveDateCustom,
      eMail: userEmail || parameters.email,
      isProceedWithoutPartner: true,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      parameters,
      petQuoteList: petQuoteResponseList,
      quoteId: store.petQuoteSelected,
    }));
    goToCustomize();
  };

  function renderButton() {
    let content = <span>Validate</span>;
    let className = 'Costco-membership-button';

    if (!editing) {
      content = <span>Edit</span>;
      className = 'Costco-membership-edit-button';
    }

    return (
      <LoadingButton
        className={className}
        isLoading={editing && loading}
        loadingColor="white"
        submit
      >
        {content}
      </LoadingButton>
    );
  }

  return (
    <div className="Costco-membership-container" id="costcoMembershipValidator">
      <h2 className="Costco-membership-header">Costco Membership</h2>

      <p className="Costco-membership-text">
        A valid Costco member ID is required. If you are not a Costco member or
        your membership has expired, please visit&nbsp;

        <a
          aria-label="Costco.com opens in a new window"
          href={COSTCO_URL}
          rel="noreferrer"
          target="_blank"
        >
          Costco.com
        </a>

        &nbsp;to purchase or renew your membership.
      </p>

      <form
        aria-label="Costco Membership"
        aria-labelledby="verifiedMembership"
        className="Costco-membership-input-container"
        id="formCostcoMembership"
        onSubmit={handleMembershipValidation}
      >
        <div className="Costco-membership-row">
          <label
            aria-label=""
            aria-labelledby="formCostcoMembership"
            className="Costco-membership-input"
            htmlFor="membership-number"
            title=""
          >
            <span>Costco Membership Number</span>

            <input
              ref={membershipInputRef}
              disabled={!editing}
              id="membership-number"
              maxLength={12}
              onChange={handleMembershipNumberChanged}
              placeholder="Required"
              readOnly={!editing}
              required
              type="text"
              value={membershipNumber}
            />
          </label>

          {renderButton()}
        </div>

        <span
          aria-live="polite"
          className="Costco-membership-error"
        >
          {error}
        </span>
      </form>

      <div className="Costco-logos-container">
        {membershipTypes
          .map((membership) => (
            <img
              key={membership.Name}
              alt=""
              aria-hidden
              className="Costco-membership-logo"
              role="presentation"
              src={membership.Image}
            />
          ))}

        <span
          aria-live="polite"
          className="Costco-membership-verified"
          id="verifiedMembership"
        >
          {message}
        </span>
      </div>

      {editing && (
        <>
          <a
            className="Costco-membership-find-number"
            href={FIND_MEMBERSHIP_URL}
            rel="noreferrer"
            target="_blank"
          >
            Where can I find my Costco Membership Number?
          </a>

          <p className="Costco-membership-no-discount">
            Proceed without Costco discount?&nbsp;

            <button
              aria-label="Click here link"
              className="Costco-membership-no-discount-link"
              id="noDiscountLink"
              onClick={handleProceedWithoutCostcoMembership}
              type="button"
            >
              Click here
            </button>
          </p>
        </>
      )}
    </div>
  );
};
